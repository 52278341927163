// Import deps
import React, { useEffect, useState } from 'react'
import { MagicBeam } from './magic-beam'
import { Barrier } from './magic-barrier'
import { useViewingWindow, isPhoneLandscape, getPadding } from './../../shared/viewing-window'

//Interface
interface LockUI {
    fire: number;
    electric: number;
    water: number;
}

interface MagicUI {
    locks: LockUI[];
    currentValue: number;
    titleHeight: number;
    handleAttack: (barrierID: number) => void;
}

// Create Puzzle component
export const MagicComponents = (props: MagicUI) => {
    // Resizing
    const {width, height} = useViewingWindow();
    const {paddingLeft, paddingTop} = getPadding();
    //Note with have to incorporate height too
    const barrierRadius = Math.min(height,width) * .2; // Fifth of the screen height or width
    // If the phone is in landscape mode, the barriers will be next to the interaction panel
    const translateWidth = ( isPhoneLandscape() )? width * .25 : width * .5;
    const translateHeight = ( isPhoneLandscape() )? height * .1 : height * .15;
    const scoreRadius = 25;
    const beamWidth = barrierRadius / 2 * 1.2 - scoreRadius/2;

    const [currentBeamColor, setCurrentBeamColor] = useState('')
    const [currentBarrierLocation, setCurrentBarrierLocation] = useState(calculateBarrierLocation(0))

    const handleAttack = (onClickEvent: any) => {
        onClickEvent.persist();
        const xCenter = translateWidth + paddingLeft;
        // 100 for the height of the title bar, 25 for radius of the center
        const yCenter = translateHeight + props.titleHeight + scoreRadius + paddingTop;
        const xCordinate = onClickEvent.clientX - xCenter;
        const yCordinate = onClickEvent.clientY - yCenter;

        const angle = getAngle(xCordinate, yCordinate);
        setCurrentBarrierLocation(angle);

        const barrierID = calculateBarrierFromAngle(angle);
        props.handleAttack(barrierID);
    }

    function getAngle(x: number, y: number) {
        // Angle (weirdly), is with the -x direction being 0
        if( y == 0 ) {
            // If x and y are both 0, there is no reference angle. Returning 0 as if x < 0 here.
            return (x > 0)? 180 : 0;
        }
        let referenceAngle = Math.abs(Math.atan(x/y) * 180 / Math.PI);
        if( x < 0 ) {
            return (y < 0) ? 90 - referenceAngle : 270 + referenceAngle;
        } else {
            return (y < 0) ? 90 + referenceAngle : 270 - referenceAngle; 
        }
    }

    function calculateBarrierLocation(lockNumber: number) {
        const lowerBound = (lockNumber - 1) * 360 / props.locks.length;
        const upperBound = (lockNumber) * 360 / props.locks.length;
        return (lowerBound + upperBound)/2;
    }

    function calculateBarrierFromAngle(angle: number) {
        let index = 0;
        let foundIndex = 0;
        // If the angle is in quadrant 2, by our weird math it needs to check based on 360 + angle
        const referenceAngle = (angle < 90) ? angle + 360 : angle;
        props.locks.map(() => {
            const lowerBound = (index) * 360 / props.locks.length + 90;
            const upperBound = (index + 1) * 360 / props.locks.length + 90;
            if(referenceAngle >= lowerBound && referenceAngle <= upperBound) {
                foundIndex = index;
            }
            index++;
        });
        return foundIndex;
    }

    return (
        <>
            <Barrier 
                locks={props.locks} 
                lockRadius={barrierRadius} 
                scoreRadius={scoreRadius} 
                translateWidth={translateWidth}
                translateHeight={translateHeight}
                handleAttack={handleAttack}
            />
            <MagicBeam 
                currentValue={props.currentValue} 
                currentColor={currentBeamColor} 
                currentBarrierLocation={currentBarrierLocation} 
                scoreRadius={scoreRadius}
                currentBarrierRadius={barrierRadius}
                translateWidth={translateWidth}
                translateHeight={translateHeight}
                beamWidth={beamWidth}
            />
        </>
    )
}