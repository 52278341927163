// Import deps
import React, { useEffect, useState } from 'react'
import { useViewingWindow, isScreenExtraSmall, isMobile, isPhoneLandscape, getPadding } from './../../shared/viewing-window'

import './../../styles/feedback.css'

interface feedbackUI {
    feedback: string,
    error: string
    puzzleStatus: string;
  }

// Create Puzzle component
export const PuzzleFeedback = (props: feedbackUI) => {
    const {width, height} = useViewingWindow();
    const {paddingLeft, paddingTop} = getPadding();
    const translateHeightFeedback = ( isPhoneLandscape() )? height * -0.05 : height * .475;
    const translateFromPadding = -paddingLeft/2;
    const translateWidthFeedback = width * .2 - translateFromPadding;
    const fontSize = ( isScreenExtraSmall() )? 12 : ( isMobile() )? 14 : 16;
    const translationFeedback = ( isPhoneLandscape() )? `translate(${translateWidthFeedback}px, ${translateHeightFeedback}px)` : `translate(${translateFromPadding}px,${translateHeightFeedback}px)`;
    const styleFeedback = {
        transform: translationFeedback,
        fontSize: `${fontSize}px`
    }
    const styleError = {
        transform: translationFeedback,
        fontSize: `${fontSize}px`
    }
    return (
        <>
            {props.puzzleStatus ? 
            (<div className='text-feedback' style={styleFeedback}>{props.puzzleStatus}</div>)
            : (<>
                <div className='text-feedback' style={styleFeedback}>{props.feedback}</div>
                <div className='text-feedback error' style={styleError}>{props.error}</div>
            </>)
            }
        </>
    )
}