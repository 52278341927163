// Import deps
import React, { useEffect, useState } from 'react'
import { InteractionPanel } from './interaction-panel'
import { MagicComponents } from './magic-components'
import { PuzzleFeedback } from './puzzle-feedback'
import { useViewingWindow, getScreenClass, isScreenExtraSmall, getPadding } from './../../shared/viewing-window'

// Import styles
import './../../styles/puzzle-general.css'


//Interface
interface StepData {
    startingValue: number;
    endingValue: number;
    interaction: string;
    changeValue: number;
    identifier: number;
}

interface LockUI {
    fire: number;
    electric: number;
    water: number;
}

interface PuzzleDataUI {
    id: number;
    title: string;
    locks: LockUI[];
    starting: number;
    options: number[];
}

type puzzleStatusUI =  'win' | 'partial' | 'loss' | 'incomplete';

interface PuzzleUI {
    puzzle: PuzzleDataUI;
    steps: StepData[];
    bestScore: number | undefined;
    savePuzzleStats: (puzzle: number, score: number, puzzleTitle: string) => void;
    handleRandomPuzzle: () => void;
    handleAddStep: (step: StepData) => void;
    handleRemoveStep: () => void;
    handleResetSteps: () => void;
    handlePuzzleFinished: (status: puzzleStatusUI) => void;
}

// Create Puzzle component
export const Puzzle = (props: PuzzleUI) => {
    const [currentValue, setCurrentValue] = useState(props.puzzle.starting);
    const [currentOptions, setCurrentOptions] = useState([...props.puzzle.options]);
    const [currentLocks, setCurrentLocks] = useState(copyLocks(props.puzzle.locks));
    const [error, setError] = useState('');   
    const [puzzleStatus, setPuzzleStatus] = useState('');
    const [puzzleFeedback, setPuzzleFeedback] = useState('');
    const [operationsRemaining, setOperationsRemaining] = useState(props.puzzle.options.length);
    const [disabledValues, setDisabledValues] = useState<number[]>([]);

    const {width, height} = useViewingWindow();
    const {paddingLeft, paddingTop} = getPadding();
    
    const titleHeight = 110;
    //If the screen is REALLY tiny, reduce the font even further
    const titleTransformX = -paddingLeft/2;
    const titleTransformY = -paddingTop;
    let titleStyle = {
        height: `${titleHeight}px`,
        transform: `translate(${titleTransformX}px, ${titleTransformY}px)`, 
        ...(isScreenExtraSmall() && { fontSize : '25px' }),
        ...(isScreenExtraSmall() && { paddingTop : '17px' })
    }

    function saveScore() {
        if(!(props.bestScore == undefined) && props.bestScore <= currentValue) {
            // If the best score is defined and better than the current value, don't save
            return;
        }
        props.savePuzzleStats(props.puzzle.id, currentValue, props.puzzle.title);
    }

    function copyLocks(input: LockUI[]) {
        let locks = input.map((lock: LockUI, idx) => (
            {...lock}
        ));
        return locks;
    }
    
    const handleCurrentValue = (operator: string, value: number, identifier: number) => {
        let valueCalculation = currentValue;
        if(!operator || currentValue === undefined) {
            return false;
        }
        //reset feedback on attempt
        setPuzzleFeedback('');

        switch(operator) {
            case '-':
                valueCalculation = valueCalculation - value;
                break;
            case '+':
                valueCalculation = valueCalculation + value;
                break;
            case 'x':
                valueCalculation = valueCalculation * value;
                break;
            case '/':
                if(value && ((valueCalculation % value) == 0)) {
                    // Don't divide by 0
                    // Division Must have no remainder
                    valueCalculation = valueCalculation / value;
                } else {
                    setError('Division resulting in a decimal is not allowed.');
                }
                break;
        }
        if(valueCalculation == currentValue) {
            //No change
            return false;
        } else {
            props.handleAddStep({
                startingValue: currentValue,
                endingValue: valueCalculation,
                interaction: operator,
                changeValue: value,
                identifier: identifier
            });
            setCurrentValue(valueCalculation);
            return true;
        } 
    }

    // If Current Value changes, check end conditions
    useEffect(() => {
        checkGameEndConditions();
    }, [currentValue]);

    const attackLock = (lockID: number) => {
        //reset feedback on attempt
        setPuzzleFeedback('');
        setError('');

        let currentLock = currentLocks[lockID];
        let typeFound = false;
        let typeAttacked = false;
        let divisible = 0;
        if(currentLock.fire) {
            divisible = 2;
        }
        if(currentLock.electric) {
            divisible = 3;
        }
        if(currentLock.water) {
            divisible = 5;
        }
        if(currentLock.fire == 0 && currentLock.electric == 0 && currentLock.water == 0) {
            setPuzzleFeedback("You do not need to attack this lock any further.");
            checkLossConditions(); // Win conditions will be checked on score change, loss conditions can be triggered on a failed attack
            return;
        }
        if( isFire(currentValue) && currentLock.fire) {
            typeFound = true;
            if(currentLock.fire <= currentValue) {
                //If the beam is greater than or equal to the fire barrier, then subtract
                let currentLocksUpdate = currentLocks;
                const beamLoss = currentLock.fire;
                currentLocksUpdate[lockID].fire = 0;

                setCurrentLocks(copyLocks(currentLocksUpdate));
                props.handleAddStep({
                    startingValue: currentValue,
                    endingValue: currentValue-beamLoss,
                    interaction: "barrier",
                    changeValue: beamLoss,
                    identifier: lockID
                });
                setCurrentValue(currentValue-beamLoss);
                typeAttacked = true;
            } else {
                setPuzzleFeedback("Power isn't strong enough.");
            }
        }

        if( isElectric(currentValue) && currentLock.electric) {
            typeFound = true;
            if(currentLock.electric <= currentValue) {
                //If the beam is greater than or equal to the electric barrier, then subtract
                let currentLocksUpdate = currentLocks;
                const beamLoss = currentLock.electric;
                currentLocksUpdate[lockID].electric = 0;
                setCurrentLocks(copyLocks(currentLocksUpdate));
                props.handleAddStep({
                    startingValue: currentValue,
                    endingValue: currentValue-beamLoss,
                    interaction: "barrier",
                    changeValue: beamLoss,
                    identifier: lockID
                });
                setCurrentValue(currentValue-beamLoss);
                typeAttacked = true;
            } else {
                setPuzzleFeedback("Power isn't strong enough.");
            }
        }

        if( isWater(currentValue) && currentLock.water) {
            typeFound = true;
            if(currentLock.water <= currentValue) {
                //If the beam is greater than or equal to the water barrier, then subtract
                let currentLocksUpdate = currentLocks;
                const beamLoss = currentLock.water;
                currentLocksUpdate[lockID].water = 0;
                setCurrentLocks(copyLocks(currentLocksUpdate));
                props.handleAddStep({
                    startingValue: currentValue,
                    endingValue: currentValue-beamLoss,
                    interaction: "barrier",
                    changeValue: beamLoss,
                    identifier: lockID
                });
                setCurrentValue(currentValue-beamLoss);
                typeAttacked = true;
            } else {
                setPuzzleFeedback("Power isn't strong enough.");
            }
        }
        if(!typeFound && !typeAttacked) {
            setPuzzleFeedback(`Attack is not the correct type. (Must be divisible by ${divisible}.)`);
        }
        if(!typeAttacked) {
            // If no types were attacked, check for loss
            checkLossConditions();
        } else {
            checkWinConditions();
        }
    }

    const checkGameEndConditions = () => {
        const win = checkWinConditions();
        if(!win) {
            checkLossConditions();
        }
    }

    const checkWinConditions = () => {
        if(currentValue != 0) {
            return false;
        }
        let win = true;
        for (let i = 0; i < currentLocks.length; i++) {
            const lock = currentLocks[i];
            if(!(lock.electric == 0 && lock.fire == 0 && lock.water == 0)
            ) {
                // Some value is not 0. So the game state is not a win
                win = false;
                break;
            }
        }
        if(win) {
            // If you are still "winning", then also check the current value
            if(currentValue == 0) {
                props.handlePuzzleFinished('win');
                saveScore();
            } else {
                win = false;
            }
        }
        return win;
    }

    const checkLossConditions = () => {
        let finishedLocks = true;
        let loss = false;
        if( operationsRemaining ) {
            // If you have operations remaining, the game isn't over yet
            return false;
        }
        for (let i = 0; i < currentLocks.length; i++) {
            const lock = currentLocks[i];
            if(!(lock.electric == 0 && lock.fire == 0 && lock.water == 0)
            ) {
                // Some value is not 0.
                finishedLocks = false;
                if((lock.fire > 0) && (currentValue >= lock.fire) && isFire(currentValue)) {
                    // You can still attack at least one barrier, even with no operations
                    return false;
                }
                if((lock.electric > 0) && (currentValue >= lock.electric) && isElectric(currentValue)) {
                    // You can still attack at least one barrier, even with no operations
                    return false;
                }
                if((lock.water > 0) && (currentValue >= lock.water) && isWater(currentValue)) {
                    // You can still attack at least one barrier, even with no operations
                    return false;
                }
                break;
            }
        }
        if( !finishedLocks ) {
            setPuzzleStatus("Loss! You didn't break all the barriers.");
            props.handlePuzzleFinished('loss');
            loss = true;
        } else {
            // No more locks
            if(currentValue > 0) {
                setPuzzleStatus('Partial success. Try to get to 0!');
                props.handlePuzzleFinished('partial');
                saveScore();
                loss = true;
            }
            if(currentValue < 0) {
                setPuzzleStatus('Not enough final energy. Loss');
                props.handlePuzzleFinished('loss');
                loss = true;
            }
        }
        return loss;
    }

    const isFire = (number: number) => {
        return (number % 2 == 0)? true : false;
    }

    const isElectric = (number: number) => {
        return (number % 3 == 0)? true : false;
    }

    const isWater = (number: number) => {
        return (number % 5 == 0)? true : false;
    }

    const resetCurrentValue = () => {
        setCurrentValue(props.puzzle.starting);
        setCurrentLocks(copyLocks(props.puzzle.locks));
        setPuzzleStatus('');
        props.handlePuzzleFinished('incomplete');
        setError('');
        setPuzzleFeedback('');
        setOperationsRemaining(props.puzzle.options.length);
        setDisabledValues([]);

        //These aren't actually updated, but resetting for consistency
        setCurrentOptions([...props.puzzle.options]);
        props.handleResetSteps();
    }

    const handleOperationsRemainingChange = (operationsRemainingChange: number) => {
        const newOperationsRemaining = operationsRemaining + operationsRemainingChange;
        if(newOperationsRemaining > 0) {
            setOperationsRemaining(newOperationsRemaining);
        }
        if( newOperationsRemaining == 0 ) {
            setOperationsRemaining(newOperationsRemaining);
        }
    }

    const handleAttack = (barrierID: number) => {
        attackLock(barrierID);
    }

    const handleUndo = () => {
        undoLastStep();
    }

    const undoLastStep = () => {
        const lastStep = props.steps[props.steps.length-1];
        if( lastStep == undefined ) {
            return;
        }
        setPuzzleStatus('');
        props.handlePuzzleFinished('incomplete');
        setError('');
        setPuzzleFeedback('');

        const index = lastStep.identifier;
        if( lastStep.interaction == 'barrier' ) {
            // If the last operation was a barrier attack, just reset the barrier
            let newLocks = [...currentLocks];
            newLocks[index] = {...props.puzzle.locks[index]};
            setCurrentLocks(newLocks);
        } else {
            // Un-disable button
            let newDisabledValues = [];
            for (let i = 0; i < disabledValues.length; i++) {
                if(disabledValues[i] != lastStep.identifier) {
                    newDisabledValues.push(disabledValues[i]);
                }
            }
            setDisabledValues([...newDisabledValues]);
            // Gain an operation back
            handleOperationsRemainingChange(1);
        }

        //In all cases, reset the current value to the starting value of the step
        setCurrentValue(lastStep.startingValue);

        props.handleRemoveStep();
    }

    const handleSetDisabledValues = (array : number[]) => {
        setDisabledValues([...array]);
    }

    //Get class for mobile vs desktop
    const screenClass=getScreenClass();
    return (
        <div className={screenClass}>
            <div className="puzzle-title title-font" style={titleStyle}> {props.puzzle.title} </div>
            <MagicComponents currentValue={currentValue} locks={currentLocks} handleAttack={handleAttack} titleHeight={titleHeight}/>
            <InteractionPanel 
                currentOptions={currentOptions}
                disabledValues={disabledValues}
                handleSetDisabledValues={handleSetDisabledValues}
                handleCurrentValue={handleCurrentValue}
                resetCurrentValue={resetCurrentValue} 
                handleOperationsRemainingChange={handleOperationsRemainingChange} 
                handleRandomPuzzle={props.handleRandomPuzzle}
                handleUndo={handleUndo}
                /> 
            <PuzzleFeedback feedback={puzzleFeedback} error={error} puzzleStatus={puzzleStatus} />
        </div>
    )
}