import React, { useEffect, useState, createContext, useContext, ReactNode  } from 'react'

interface globalContextUI {
  width: number;
  height: number;
}

interface globalPaddingUI {
  paddingLeft: number;
  paddingTop: number;
}


interface globalContextChildren {
  children: ReactNode;
}

const viewingWindowContext = createContext<globalContextUI>({width: window.innerWidth, height: window.innerHeight});
const viewingWindowPadding = createContext<globalPaddingUI>({paddingLeft: 0, paddingTop: 0});

export const ViewingWindowProvider = ( {children}: globalContextChildren ) => {
  const calculateHeight = () => {
    const maxHeight = 1000;
    return (window.innerHeight > maxHeight)? maxHeight : window.innerHeight;
  }

const forceWidthScaling = (width: number, height: number) => {
    let scaledWidth = width;
    if(isPhoneLandscapeForDimensions(width, height)) {
      // Landscape mode, want width = height * 2
      if( height * 2 > width) {
        //Width is equal to original width
      } else {
        scaledWidth = height * 2;
      }
    } else {
      // Portrait mode, wand height = width * 2
      if( width * 2 > height) {
        scaledWidth = height / 2;
      } else {
        //Width is equal to original width
      }
    }
    return scaledWidth;
}

const forceHeightScaling = (width: number, height: number) => {
    let scaledHeight = height;
    if(isPhoneLandscapeForDimensions(width, height)) {
      // Landscape mode, want width = height * 2
      if( height * 2 > width) {
        scaledHeight = width / 2;
      } else {
        //Height is equal to original height
      }
    } else {
      // Portrait mode, wand height = width * 2
      if( width * 2 > height) {
        //Height is equal to original height
      } else {
        scaledHeight = width * 2;
      }
    }
    return scaledHeight;
}

const calculatePaddingLeft = (width:number, actualWidth:number) => {
  return (actualWidth - width)/2;
}
const calculatePaddingTop = (height:number, actualHeight:number) => {
  return (actualHeight - height)/2;
}


  const [width, setWidth] = useState(forceWidthScaling(window.innerWidth, calculateHeight()));
  const [height, setHeight] = useState(forceHeightScaling(window.innerWidth, calculateHeight()));
  const [actualWidth, setActualWidth] = useState(window.innerWidth);
  const [actualHeight, setActualHeight] = useState(calculateHeight());
  const [paddingLeft, setPaddingLeft] = useState(calculatePaddingLeft(width, actualWidth));
  const [paddingTop, setPaddingTop] = useState(calculatePaddingTop(height, actualHeight));

  const handleWindowResize = () => {
    //Set Max width and height and padding on resize:
    setActualWidth(window.innerWidth);
    setActualHeight(calculateHeight());

    const forcedWidth = forceWidthScaling(window.innerWidth, calculateHeight());
    const forcedHeight = forceHeightScaling(window.innerWidth, calculateHeight());
    setWidth(forcedWidth);
    setHeight(forcedHeight);

    setPaddingLeft(calculatePaddingLeft(forcedWidth, window.innerWidth));
    setPaddingTop(calculatePaddingTop(forcedHeight, calculateHeight()));
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  useEffect(() => {
    window.addEventListener("orientationchange", handleWindowResize);
    return () => window.removeEventListener("orientationchange", handleWindowResize);
  }, []);
  
  return (
    <viewingWindowContext.Provider value={{ width, height }}>
      <viewingWindowPadding.Provider value={{ paddingLeft, paddingTop }}>
        <div style={{paddingLeft: paddingLeft.toString() + 'px', paddingTop: paddingTop.toString() + 'px'}}>
          {children}
        </div>
      </viewingWindowPadding.Provider>
    </viewingWindowContext.Provider>
  );
};

export const useViewingWindow = () => {
  /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
  const { width, height } = useContext(viewingWindowContext);
  return { width, height };
}

export const getScreenClass = () => {
  // Logic for adding classes to resize elements based on assumed viewing window
  const { width, height } = useContext(viewingWindowContext);
  return getScreenClassForDimensions(width, height);
}

export const getViewType = () => {
  const { width, height } = useContext(viewingWindowContext);
  return getViewTypeForDimensions(width, height);
}

export const isMobile = () => {
  const screen = getScreenClass();
  return (screen == 'mobile')? true : false;
}

export const isPhoneLandscape = () => {
  const view = getViewType();
  return (view == 'landscape' && isMobile())? true : false;
}

export const isScreenExtraSmall = () => {
  const { width, height } = useContext(viewingWindowContext);
  const viewType = getViewType();
  if( viewType == 'portrait' && width < 400 ) {
    return true;
  } else if( viewType == 'landscape' && height < 400 ) {
    return true;
  }
  
  return false;
}

// Methods for calculation based on input values, rather than react values in memory
export const getScreenClassForDimensions = (width: number, height: number) => {
  // Logic for adding classes to resize elements based on assumed viewing window
  const viewType = getViewTypeForDimensions(width, height);
  let screen = 'desktop';
  if( width < 700 && viewType == "portrait" ) {
    screen = 'mobile';
  }
  if( height < 700 && viewType == "landscape" ) {
    screen = 'mobile';
  }
  
  return screen;
}

export const getViewTypeForDimensions = (width: number, height: number) => {
  let screen = 'portrait';
  if( width > height ) {
    screen = 'landscape';
  }
  
  return screen;
}

export const isMobileForDimensions = (width: number, height: number) => {
  const screen = getScreenClassForDimensions(width, height);
  return (screen == 'mobile')? true : false;
}

export const isPhoneLandscapeForDimensions = (width: number, height: number) => {
  const view = getViewTypeForDimensions(width, height);
  return (view == 'landscape' && isMobileForDimensions(width, height))? true : false;
}

export const getPadding = () => { 
  const { paddingTop, paddingLeft } = useContext(viewingWindowPadding);
  return { paddingTop, paddingLeft };
}